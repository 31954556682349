import * as Configuration from './configuration'
import * as I18n from '@rushplay/i18n'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Suspense from './suspense'

import {BrandThemeProvider} from './brand-theme-provider'
import PropTypes from 'prop-types'
import {Router} from './router'
import {TranslationProvider} from './translation-provider'

/**
 * Extracted logic for data-providers to application
 * @component GlobalProvider
 * @param {Object} props Components props
 * @param {ReactNode} props.children Application consumer
 * @param {string} props.location Location for Router
 * @param {Object} props.store Redux store
 * @param {Object} props.translationLoader Loader for translations
 * @returns {ReactNode}
 */
export function GlobalProvider(props) {
  const state = props.store.getState() || {}

  const languageCodes = Configuration.getTranslationProviderLanguageCodes(state)
  // Fallback to 'en' when languages are not passed in query to show error-pages
  // Otherwise the app crashes and you don't know why
  const languages = languageCodes.length === 0 ? ['en'] : languageCodes

  return (
    <Suspense.Provider>
      <I18n.LoaderProvider loader={props.translationLoader}>
        <ReactRedux.Provider store={props.store}>
          <Router location={props.location}>
            <BrandThemeProvider>
              <TranslationProvider language={languages}>
                {props.children}
              </TranslationProvider>
            </BrandThemeProvider>
          </Router>
        </ReactRedux.Provider>
      </I18n.LoaderProvider>
    </Suspense.Provider>
  )
}

GlobalProvider.propTypes = {
  children: PropTypes.element,
  location: PropTypes.string,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
  translationLoader: PropTypes.object.isRequired,
}
