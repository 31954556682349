import * as I18n from '@rushplay/i18n'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import Url from 'url'
import {useTransactionContext} from '../transaction-provider'

const IFRAME_NAME = 'transactionForm'

/**
 * Post message type for iframe communication.
 *
 * @constant
 * @type {string}
 */
export const COMPLETED = 'payments/COMPLETED'

function Form(props) {
  const ref = React.useRef()
  const url = Url.parse(props.redirectParams.url, true)

  React.useEffect(() => {
    if (ref.current) {
      ref.current.submit()
    }
  }, [ref])

  return (
    <form
      action={props.redirectParams.url}
      method={props.redirectParams.method}
      ref={ref}
      target={props.target}
      data-testid="form"
    >
      {R.isEmpty(props.redirectParams.parameters)
        ? R.map(
            (key) => (
              <input
                key={key}
                name={key}
                type="hidden"
                value={url.query[key]}
              />
            ),
            R.keys(url.query)
          )
        : R.map(
            (key) => (
              <input
                key={key}
                name={key}
                type="hidden"
                value={props.redirectParams.parameters[key]}
              />
            ),
            R.keys(props.redirectParams.parameters)
          )}
    </form>
  )
}

Form.propTypes = {
  redirectParams: PropTypes.shape({
    method: PropTypes.oneOf(['GET', 'POST']),
    parameters: PropTypes.object,
    url: PropTypes.string.isRequired,
  }),
  target: PropTypes.string.isRequired,
}

function ManualLink(props) {
  const translate = I18n.useTranslate()

  // Note: This was originally added for iPhone users using Volt, after Apple started to
  // more block popups (without indicating that a popup was blocked).
  if (R.isEmpty(props.redirectParams?.parameters)) {
    return (
      <React.Fragment>
        <ThemeUi.Box
          sx={{
            pt: 2,
            fontSize: 1,
            lineHeight: 'body',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <ThemeUi.Text sx={{fontWeight: 'bold'}}>
            {translate('active-transaction.manual-link.info')}&nbsp;
            <ThemeUi.Button
              as="a"
              rel="noopener noreferrer"
              target="_blank"
              sx={{mt: 1}}
              href={props.redirectParams.url}
            >
              {translate('active-transaction.manual-link.link')}
            </ThemeUi.Button>
          </ThemeUi.Text>
        </ThemeUi.Box>
      </React.Fragment>
    )
  } else {
    return null
  }
}

ManualLink.propTypes = {
  redirectParams: PropTypes.shape({
    method: PropTypes.oneOf(['GET', 'POST']),
    parameters: PropTypes.object,
    url: PropTypes.string.isRequired,
  }),
}

function Iframe(props) {
  const transaction = useTransactionContext()

  return (
    <iframe
      data-testid="iframe"
      height={transaction.redirectParams.height}
      name={props.name}
      src={props.src}
      sandbox="allow-downloads-without-user-activation allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation allow-top-navigation-by-user-activation"
      style={{maxWidth: '100%'}}
      width={transaction.redirectParams.width}
    />
  )
}

Iframe.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
}

/**
 * Active Transaction render props component
 */
export function ActiveTransaction() {
  const translate = I18n.useTranslate()
  const transaction = useTransactionContext()

  if (transaction.redirectParams == null) {
    return null
  }

  if (transaction.redirectParams.html) {
    const encodedHtml = encodeURIComponent(transaction.redirectParams.html)
    return <Iframe src={`data:text/html,${encodedHtml}`} />
  }

  switch (transaction.redirectParams.container) {
    case 'iframe': {
      const isPostRedirect = R.test(/POST/i, transaction.redirectParams.method)

      return (
        <>
          <Iframe
            name={IFRAME_NAME}
            src={isPostRedirect ? null : transaction.redirectParams.url}
          />
          {isPostRedirect && (
            <Form
              target={IFRAME_NAME}
              redirectParams={transaction.redirectParams}
            />
          )}
        </>
      )
    }

    case 'window': {
      // TODO: check with designers what to show here
      return (
        <>
          <Form target="_blank" redirectParams={transaction.redirectParams} />
          <ThemeUi.Flex
            sx={{
              pt: 2,
              fontSize: 1,
              lineHeight: 'body',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            {translate('active-transaction.successful-redirection')}
          </ThemeUi.Flex>

          {translate('active-transaction.manual-link.info') != null && (
            <ManualLink redirectParams={transaction.redirectParams} />
          )}
        </>
      )
    }

    default: {
      return null
    }
  }
}

I18n.Loader.preload(
  [
    'active-transaction.successful-redirection',
    'active-transaction.manual-link.info',
    'active-transaction.manual-link.link',
  ],
  ActiveTransaction
)
