import * as Configuration from './configuration'
import * as I18n from '@rushplay/i18n'
import * as Icons from './icons'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUI from 'theme-ui'

import PropTypes from 'prop-types'

const DEBUG_ALL = 'all'
const DEBUG_MISSING = 'missing'
const DEBUG_OFF = 'off'

export function TranslationProvider(props) {
  const [debug, setDebug] = React.useState(DEBUG_OFF)
  const environment = ReactRedux.useSelector(Configuration.getEnvironment)
  const [settingsVisible, setSettingsVisible] = React.useState(false)
  const brand = ReactRedux.useSelector(Configuration.getBrand)

  if (environment !== 'production') {
    return (
      <I18n.TranslationProvider
        language={props.language}
        bucket={brand}
        debug={debug !== DEBUG_OFF ? debug : undefined}
      >
        <ThemeUI.Box
          sx={{display: 'flex', flexDirection: 'column', width: '100%'}}
        >
          {props.children}
          <ThemeUI.Box
            sx={{
              position: 'fixed',
              bottom: '0px',
              left: '0px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: settingsVisible ? 'white' : 'transparent',
              borderTop: settingsVisible ? '1px solid #e4e4e4' : 'none',
            }}
          >
            {settingsVisible && (
              <>
                <ThemeUI.Heading as="h5" sx={{px: 0, pt: 0}}>
                  Settings
                </ThemeUI.Heading>
                <ThemeUI.Heading as="h6" sx={{px: 0, pt: 0}}>
                  View translation keys:
                </ThemeUI.Heading>
                <ThemeUI.Box
                  sx={{
                    width: '100%',
                    fontSize: 1,
                    display: 'flex',
                    gap: 0,
                    p: 0,
                  }}
                >
                  {[DEBUG_ALL, DEBUG_MISSING, DEBUG_OFF].map((mode) => (
                    <ThemeUI.Box
                      key={mode}
                      as="label"
                      htmlFor={mode}
                      sx={{
                        'verticalAlign': 'middle',
                        'cursor': 'pointer',
                        'bg': debug === mode ? 'primary' : 'muted',
                        'color': debug === mode ? 'white' : 'text',
                        'fontWeight': 'bold',
                        'textAlign': 'center',
                        'p': 0,
                        'borderRadius': 0,
                        'flex': '1 1 80px',
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        '&:hover': {
                          bg: 'primary',
                          color: 'white',
                        },
                      }}
                    >
                      <ThemeUI.Box
                        id={mode}
                        as="input"
                        type="radio"
                        name={mode}
                        value={mode}
                        checked={debug === mode}
                        onChange={() => setDebug(mode)}
                        sx={{
                          mr: '4px',
                          verticalAlign: 'middle',
                          opacity: 0,
                          zIndex: '-1',
                          overflow: 'hidden',
                          position: 'absolute',
                          width: '0',
                          height: '0',
                        }}
                      />
                      {mode !== DEBUG_OFF ? `Show ${mode}` : 'Off'}
                    </ThemeUI.Box>
                  ))}
                </ThemeUI.Box>
              </>
            )}
            <ThemeUI.Box
              title="toggle settings menu (staging only)"
              sx={{
                'cursor': 'pointer',
                'borderTopRightRadius': 1,
                'backgroundColor': settingsVisible ? 'text' : '#e4e4e4',
                'color': settingsVisible ? '#e4e4e4' : 'text',
                'p': '4px',
                'width': '30px',
                'height': '30px',
                '&:hover': {
                  backgroundColor: 'text',
                  color: '#e4e4e4',
                },
              }}
              onClick={() => setSettingsVisible((value) => !value)}
            >
              <Icons.Settings />
            </ThemeUI.Box>
          </ThemeUI.Box>
        </ThemeUI.Box>
      </I18n.TranslationProvider>
    )
  }

  return (
    <I18n.TranslationProvider bucket={brand} language={props.language}>
      {props.children}
    </I18n.TranslationProvider>
  )
}

TranslationProvider.propTypes = {
  children: PropTypes.element,
  language: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
}
